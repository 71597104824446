import session from 'resources/session';

const DEFAULT_TIMEOUT = 20000; // ms
const DEFAULT_CONTENT_TYPE = 'application/json';

export class Request {
  get(options) {
    options.type = 'GET';
    return this._request(options);
  }

  post(options) {
    options.type = 'POST';
    return this._request(options);
  }

  patch(options) {
    options.type = 'PATCH';
    return this._request(options);
  }

  delete(options) {
    options.type = 'DELETE';
    return this._request(options);
  }

  _request(options) {
    return session.get().then(session_token => {
      if (!options.url) throw new Error('You must provide a URL.');

      options.headers = options.headers || {};
      options.headers.Authorization = `Bearer ${session_token}`;

      options.contentType = options.contentType || DEFAULT_CONTENT_TYPE;
      options.timeout = options.timeout || DEFAULT_TIMEOUT;

      return new Promise((resolve, reject) => {
        options.success = resolve;
        options.error = (xhr, ajaxOptions, error) => {
          // check for unauthorized
          if (
            !options.regenToken &&
            (xhr.status === 401 || xhr.status === 403)
          ) {
            // If we're unauthorized, we try again with a new JWT since the old one has expired.
            session.clear();
            options.regenToken = true;
            this._request(options)
              .then(resolve)
              .catch(reject);
          } else {
            reject(error);
          }
        };

        options.crossDomain = true;
        $.ajax(options);
      });
    });
  }
}

const request = new Request();

export default request;
