import * as React from 'react';
import moment from 'moment';
import 'moment-timezone';
import 'moment-duration-format';
import { clamp } from 'utils/math';
const styles = require('./styles.scss');

interface DurationPickerProps {
  duration: moment.Duration;
  disabled: boolean;
  hasSeconds?: boolean;
  onChange: (duration: moment.Duration) => void;
}

interface DurationPickerState {
  dropdownVisible: boolean;
  duration: moment.Duration;
}

interface NumberInputProps {
  value: number;
  label: string;
  className: string;
  menuHeight?: string;
  min?: number;
  max?: number;
  disabled?: boolean;
  onChange: (value: number) => void;
}

interface NumberInputState {
  error: boolean;
  value: string;
}

class NumberInput extends React.PureComponent<
  NumberInputProps,
  NumberInputState
> {
  static defaultProps = {
    min: 0,
    max: 60,
    menuHeight: '400px',
    disabled: false,
  };

  state = { error: false, value: '' };

  constructor(props: NumberInputProps) {
    super(props);
    this.state.value = props.value.toString();
  }

  componentWillReceiveProps(newProps: NumberInputProps) {
    this.setState({ value: newProps.value.toString() });
  }

  onChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value;
    this.setState({ value });

    if (/^\d+$/.test(value)) {
      const num = clamp(
        Number.parseInt(value, 10),
        this.props.min!,
        this.props.max!,
      );
      this.props.onChange(num);
      this.setState({ value: num.toString(), error: false });
    } else {
      this.setState({ error: true });
    }
  };

  onBlur: React.ChangeEventHandler<HTMLInputElement> = event => {
    const value = this.props.value.toString();
    this.setState({ value });
  };

  render() {
    return (
      <div className={styles.autoComplete}>
        {this.renderInput({
          value: this.state.value,
          disabled: this.props.disabled,
          onChange: this.onChange,
          onBlur: this.onBlur,
        })}
      </div>
    );
  }

  renderInput = (props: React.InputHTMLAttributes<HTMLInputElement>) => (
    <div className={styles.labelNested}>
      <div>
        <label htmlFor={`${this.htmlId}`}>{this.props.label}</label>
        <input
          id={`${this.htmlId}`}
          className={this.props.className}
          type="number"
          {...props}
        />
      </div>
    </div>
  );

  get htmlId() {
    return this.props.className + new Date().getDate();
  }
}

export default class DurationPicker extends React.PureComponent<
  DurationPickerProps,
  DurationPickerState
> {
  id: string;

  static defaultProps = {
    hasSeconds: false,
  };

  constructor(props: DurationPickerProps) {
    super(props);
    this.id = `durationPickerid${new Date().getTime()}`;
    this.state = {
      dropdownVisible: false,
      duration: props.duration,
    };
  }

  componentWillReceiveProps(nextProps: DurationPickerProps) {
    this.setState({
      duration: nextProps.duration,
    });
  }

  durationFromTime = (
    hour: number,
    minute: number,
    second: number,
  ): moment.Duration => {
    const durationString = `PT${hour}H${minute}M${second}S`;
    return moment.duration(durationString);
  };

  onChangeHours = (hours: number) => {
    const duration = this.durationFromTime(
      hours,
      this.state.duration.minutes(),
      this.state.duration.seconds(),
    );
    this.setState({ duration });
    this.updateParent(duration);
  };

  onChangeMinutes = (minutes: number) => {
    const duration = this.durationFromTime(
      Math.trunc(this.state.duration.asHours()),
      minutes,
      this.state.duration.seconds(),
    );
    this.setState({ duration });
    this.updateParent(duration);
  };

  onChangeSeconds = (seconds: number) => {
    const duration = this.durationFromTime(
      Math.trunc(this.state.duration.asHours()),
      this.state.duration.minutes(),
      seconds,
    );
    this.setState({ duration });
    this.updateParent(duration);
  };

  updateParent = (duration: moment.Duration) => {
    this.props.onChange(duration);
  };

  render() {
    return (
      <div className={styles.durationPicker}>
        <NumberInput
          min={0}
          max={365 * 10 /* 10 years */}
          className="hours-input"
          label="Hours"
          value={Math.trunc(this.props.duration.asHours())}
          onChange={this.onChangeHours}
          disabled={this.props.disabled}
        />
        <NumberInput
          min={0}
          max={60}
          menuHeight=""
          className="minutes-input"
          label="Minutes"
          value={this.props.duration.minutes()}
          onChange={this.onChangeMinutes}
          disabled={this.props.disabled}
        />
        {this.props.hasSeconds ? (
          <NumberInput
            min={0}
            max={60}
            className="seconds-input"
            label="Seconds"
            value={this.props.duration.seconds()}
            onChange={this.onChangeSeconds}
            disabled={this.props.disabled}
          />
        ) : null}
      </div>
    );
  }
}
