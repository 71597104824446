import Base from 'resources/base';
import request from 'lib/request';
import ApiRoutes from 'lib/api_routes';

export default class Incident extends Base {
  constructor(attributes) {
    if (!attributes.page_id) throw new Error('You must provide a page_id');

    attributes.routes = {
      index: options => {
        return Routes.page_incidents_url.bind(null, {
          page_id: attributes.page_id,
          host: Base.api_host,
          port: Base.api_port,
        });
      },
      show: options => {
        return Routes.page_incident_url.bind(null, {
          incident_id: options.incident_id,
          page_id: attributes.page_id,
          host: Base.api_host,
          port: Base.api_port,
        });
      },
    };

    super(attributes);
  }

  static searchRoute(options) {
    if (!options.page_id) throw new Error('You must provide a page_id');
    return ApiRoutes.page_incidents_url({ pageCode: options.page_id });
  }

  static search(queryOptions) {
    let url;
    if (queryOptions.url) {
      url = queryOptions.url;
    } else {
      url = Incident.searchRoute({ page_id: queryOptions.page_id });
    }

    return request
      .get({
        url: url,
        data: { q: queryOptions.query },
      })
      .then(response => {
        const results = response;
        return results.map(result => {
          let incident = new Incident(result);
          incident.highlights = result['highlight'];
          return incident;
        });
      });
  }

  _serializableObject() {
    return {
      name: this.name,
      status: this.status,
      message: this.message,
      impact: this.impact,
      created_at: this.created_at,
      updated_at: this.created_at,
      scheduled_for: this.scheduled_for,
      scheduled_until: this.scheduled_until,
    };
  }
}
