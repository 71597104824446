import LockIcon from '@atlaskit/icon/glyph/lock';
import { colors } from '@atlaskit/theme';
import Tooltip from '@atlaskit/tooltip';
import React from 'react';

export const TooltipForbiddenAccess = () => (
  <Tooltip content="You don’t have permission to view this page.">
    <LockIcon label="Not allowed to access" primaryColor={colors.N70} />
  </Tooltip>
);

export default TooltipForbiddenAccess;
